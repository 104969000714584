import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import WindowContext, { IWindowContext } from "../../contexts/WindowContext";
import { WindowWidget } from "./styles";

interface DragWindowProps extends PropsWithChildren {
    onOpen?: (callback?: Function) => void;
    onClose: (callback?: Function) => void;
}

export default function WindowWrapper({ onClose, onOpen, ...props }: DragWindowProps) {

    const [isClosed, setIsClosed] = useState(false);

    const [windowContext] = useState<IWindowContext>({
        closeWindow: () => { setIsClosed(true); onClose(); },
        openWindow: () => { }
    });

    return <WindowContext.Provider value={windowContext}>
        <DraggableWindow {...props} style={{ display: isClosed ? "none" : "block" }}></DraggableWindow>
    </WindowContext.Provider>
}

export function useDraggableWindow() {
    const context = useContext(WindowContext);
    return context;
}

export function DraggableWindow({ children, width, height, style }: any) {
    const [isDragging, setIsDragging] = useState(false);
    const [xTranslate, setXTranslate] = useState(0);
    const [yTranslate, setYTranslate] = useState(0);
    const [initialMousePosition, setInitialMousePosition] = useState({
        x: 0,
        y: 0
    });
    const onMouseDown = ({ clientX, clientY }: any) => {
        setInitialMousePosition({ x: clientX, y: clientY });
        setIsDragging(true);
    };

    useEffect(() => {
        const onMouseMove = (e: MouseEvent) => {
            setXTranslate(xTranslate + e.clientX - initialMousePosition.x);
            setYTranslate(yTranslate + e.clientY - initialMousePosition.y);
        };
        if (isDragging) {
            window.addEventListener("mousemove", onMouseMove);
        }
        return () => window.removeEventListener("mousemove", onMouseMove);
    }, [isDragging, initialMousePosition]);

    useEffect(() => {
        const onMouseUp = () => setIsDragging(false);
        window.addEventListener("mouseup", onMouseUp);
        return () => window.removeEventListener("mouseup", onMouseUp);
    }, []);

    return (
        <WindowWidget style={{ position: "fixed" }}>
            <div
                style={{ transform: `translate(${xTranslate}px,${yTranslate}px)`, ...style }}
                onMouseDown={onMouseDown}
            >
                <div style={{ padding: "25px" }}>
                    {children}
                </div>
            </div>
        </WindowWidget>
    )
}