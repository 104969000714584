import React from 'react';
import {
    Propriedade,
    TextoValor
} from "../styles";

interface ShowPropertyProps {
    label: string;
    value: string | string[];
}

function ShowProperty({ label, value }: ShowPropertyProps) {
    const showData = value != null;
    try {

        return (
            <>
                <Propriedade>{label}:</Propriedade>
                {showData ?
                    <TextoValor>{!Array.isArray(value) ? value : value.map(e => e || "-").join("")}</TextoValor> :
                    <TextoValor> - </TextoValor>
                }
            </>
        );
    } catch (e) {
        console.log(e, value);
        return <p>ERRO</p>
    }
}

export default ShowProperty;
