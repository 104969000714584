import styled from "styled-components";

const Container = styled.div`
    background-color: black;
    width: 800px;
    border-radius: 4px;
`;

const Output = styled.pre`
    margin: 0;
    overflow-y: scroll;
    max-height: 100%;
    color: #fff;
`

const Header = styled.div`
    padding: 5px;
    background-color: #2d2d30;
    font-size: 14px;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    border-radius: inherit;

    & p {
        font-family: Arial, Helvetica, sans-serif;
        color: white;
        margin: 0;
    }
`


export { Header, Output, Container };