import React from "react";
import { useWindow } from "../../hooks/useWindow";
import { Container, Header, Output } from "./styles";
interface ConsoleProps {
    output: string;
}

export default function Console({ output }: ConsoleProps) {
    const window = useWindow();
    
    return (<Container>
        <Header>
            <p>Welcome to INS Console</p><button onClick={() => window.closeWindow()}>X</button>
        </Header>
        <Output>{output}</Output>
    </Container>)
}