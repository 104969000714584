
import { ApiBase, BaseApi, RespostaHttp } from "rids";
import Equipamento from "../dtos/Equipamento";
import Provisionamento from "../dtos/Provisionamento";

interface Dictionary {
    [key: string]: string;
}

interface WindowConfig extends Window {
    config: any;
}

const myWindow = window as unknown as WindowConfig;

// console.log("window: ", myWindow.config);

// addConfig(myWindow.config);

interface TelemetriaApi extends ApiBase {
    buscarDadosIns: (dados: any, config?: any) => Promise<RespostaHttp<Equipamento>>;
    encontrarEquipamento: (dados: any, config?: any) => Promise<RespostaHttp<Equipamento>>;
    buscarDadosEquipamento: (dados: any, config?: any) => Promise<RespostaHttp<Equipamento>>;
    reiniciarEquipamento: (dados: any, config?: any) => Promise<RespostaHttp<Equipamento>>;
    ping: (dados: any, config?: any) => Promise<RespostaHttp<Provisionamento>>;
    defaultParams: (config?: any) => Promise<RespostaHttp<Dictionary>>;
}

const telemetriaApi = BaseApi("telemetria") as TelemetriaApi;

telemetriaApi.buscarDadosIns = function (dados: any, config?: any) {
    return this._api.post<Equipamento>("consultarDadosIns", dados, config) as Promise<RespostaHttp<Equipamento>>;
}

telemetriaApi.encontrarEquipamento = function (dados: any, config?: any) {
    return this._api.post<Equipamento>("encontrarEquipamento", dados, config) as Promise<RespostaHttp<Equipamento>>;
}

telemetriaApi.buscarDadosEquipamento = function (dados: any, config?: any) {
    return this._api.post<Equipamento>("dadosEquipamento", dados, config) as Promise<RespostaHttp<Equipamento>>;
}

telemetriaApi.reiniciarEquipamento = function (dados: any, config?: any) {
    return this._api.post<Equipamento>("reiniciarEquipamento", dados, config) as Promise<RespostaHttp<Equipamento>>;
}

telemetriaApi.ping = function (dados: any, config?: any) {
    return this._api.post<Provisionamento>("ping", dados, config) as Promise<RespostaHttp<Provisionamento>>;
}

telemetriaApi.defaultParams = function (config?: any) {
    return this._api.get<Dictionary>("defaultParams", config) as Promise<RespostaHttp<Dictionary>>;
}

export default telemetriaApi;