import './global.css';
import {
  Container,
  Rodape
} from "./styles";
import { ThemeProvider } from 'styled-components';

import { AlertProvider, RidsApp, theme } from "rids";
import Telemetria from './pages/Telemetria';

function App() {
  const ano = new Date().getFullYear();
  return (
    <AlertProvider>
      <RidsApp rotas={[]}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <Container>
              {/* <Titulo>Telemetria</Titulo> */}
              <Telemetria></Telemetria>
              <Rodape>
                © {ano} Interfocus Tecnologia. Todos os direitos reservados.
              </Rodape>
            </Container>
          </div>
        </ThemeProvider>
      </RidsApp>
    </AlertProvider>
  );
}

export default App;
