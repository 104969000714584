import { useContext, ReactNode } from "react";
import WindowContext, { IWindowContext } from "../contexts/WindowContext";

interface WindowProviderProps {
    children: ReactNode;
}

function useWindow(): IWindowContext {
    const context = useContext(WindowContext);
    return context;
}

export { useWindow };