import React, { useCallback, useEffect, useState } from "react";
import { Button, icons, parseQueryString, RidsGlobalStyle, AlertBox, useAlert } from "rids";
import styled from "styled-components";
import Console from "../components/Console";
import WindowWrapper from "../components/DraggableWindow";
import ShowProperty from "../components/ShowProperty";
import { ColunaDado, ColunaHeader } from "../components/TabelaEquipamento";
import Equipamento from "../dtos/Equipamento";
import telemetriaService from "../services/telemetriaService";
import { Cabecalho, ContainerAlarmes, ContainerDispositivo, ContainerEquipamentos, GrupoInfo, Propriedades, Subtitulo, Trafego, ValoresTelemetria } from "../styles";


const ContainerIcon = styled.div`
    width: 16px;
    height: 16px;
`
const Botao = styled(Button)`
    @media print{
      display: none;
    }
`;

export default function Telemetria() {

    const query = parseQueryString();
    const [config] = useState({
        headers: {
            "IntegrationSecret": query["integrationSecret"]
        }
    });

    const [dados, setDados] = useState(query as Equipamento);

    const [buscouIns, setBuscouIns] = useState(false);
    const [buscouEquipamento, setBuscouEquipamento] = useState(false);
    const [leituraPendente, setLeituraPendente] = useState(true);
    const [isResetting, setIsResetting] = useState(false);

    const [canReset, setCanReset] = useState(false);
    const [pingReceived, setPingReceived] = useState("");
    const [canPing, setCanPing] = useState(true);
    const [canEnableAccess, setCanEnableAccess] = useState(false);

    const [disableRefresh, setDisableRefresh] = useState(true);
    const [disablePing, setDisablePing] = useState(true);
    const [disableEnableAccess, setDisableEnableAccess] = useState(true);

    const alerts = useAlert();

    // const addWindow = useCallback(() => {
    //     const newDados = [...windows];
    //     newDados.push({} as never);
    //     setWindows(newDados);
    // }, [windows]);

    const mergeDados = useCallback(async (result: Equipamento) => {
        const newDados = { ...dados, ...result };
        setDados(newDados);
    }, [dados]);

    const exportPdf = useCallback(() => {
        window.print();
    }, []);

    const buscarIns = useCallback(async () => {
        alerts.createAlert("ALERT_TELEMETRIA", {
            state: "info",
            title: "Buscando dados no INS",
            message: "As informações serão preenchidas, por favor aguarde!",
            actionEvent: "close",
            actionLabel: "",
            timeout: 5000,
            isRelative: true,
            alertPosition: "left"
        });

        const resultDados = await telemetriaService.defaultParams(config);

        if (resultDados.success) {
            const operacoes = resultDados.data["OPERACOES_LIBERADAS"];
            if (operacoes) {
                const operacoesPermitidas = new Set([...operacoes.split(",")]);
                setCanReset(operacoesPermitidas.has("RESET"));
                setCanEnableAccess(operacoesPermitidas.has("REMOTE_ACCESS"));
                setCanPing(operacoesPermitidas.has("PING"));
            }
        }

        const result = await telemetriaService.buscarDadosIns(dados, config);
        if (result.success) {
            mergeDados(result.data);
            setBuscouIns(true);
        } else {
            setTimeout(() => {
                alerts.createAlert("ALERT_TELEMETRIA", {
                    state: "error",
                    title: "Falha na busca de dados no INS!",
                    message: "Por favor verifique os dados e tente novamente.",
                    actionEvent: "close",
                    actionLabel: "",
                    timeout: 5000,
                    isRelative: true,
                    alertPosition: "left",
                });
            }, 2000);
        }

    }, [mergeDados, dados, config]);

    const buscarEquipamento = useCallback(async () => {
        alerts.createAlert("ALERT_TELEMETRIA", {
            state: "info",
            title: "Buscando equipamento na OLT",
            message: "As informações serão preenchidas, por favor aguarde!",
            actionEvent: "close",
            actionLabel: "",
            timeout: 5000,
            isRelative: true,
            alertPosition: "left",
        });

        const result = await telemetriaService.encontrarEquipamento(dados, config);
        if (result.success) {
            mergeDados(result.data);
            setBuscouEquipamento(true);
        }else{
            alerts.createAlert("ALERT_TELEMETRIA", {
                state: "error",
                title: "Falha na busca de equipamento na OLT!",
                message: "Por favor verifique os dados e tente novamente.",
                actionEvent: "close",
                actionLabel: "",
                timeout: 5000,
                isRelative: true,
                alertPosition: "left",
            });
        }
    }, [mergeDados, dados, config]);

    const buscarTelemetria = useCallback(async () => {
        alerts.createAlert("ALERT_TELEMETRIA", {
            state: "info",
            title: "Consultando níveis de sinal",
            message: "As informações serão preenchidas, por favor aguarde!",
            actionEvent: "close",
            actionLabel: "",
            timeout: 5000,
            isRelative: true,
            alertPosition: "left",
        });

        setDisableRefresh(true);
        const result = await telemetriaService.buscarDadosEquipamento(dados, config);
        if (result.success) {
            mergeDados(result.data);
            setLeituraPendente(false);
            setDisableRefresh(false);
            setDisableEnableAccess(false);
            setDisablePing(false);
        }else{
            alerts.createAlert("ALERT_TELEMETRIA", {
                state: "error",
                title: "Falha na consulta de níveis de sinal!",
                message: "Por favor verifique os dados e tente novamente.",
                actionEvent: "close",
                actionLabel: "",
                timeout: 5000,
                isRelative: true,
                alertPosition: "left",
            });
        }
    }, [mergeDados, setDisableRefresh, dados, config]);

    const reiniciar = useCallback(async () => {
        setIsResetting(true);
        const result = await telemetriaService.reiniciarEquipamento(dados, config);
        if (result.success) {
            mergeDados(result.data);
            setIsResetting(false);
        }
    }, [mergeDados, setIsResetting, dados, config]);

    const executarPing = useCallback(async () => {
        setDisablePing(true);
        const resultPing = await telemetriaService.ping(dados, config);
        if (resultPing.success) {
            setPingReceived(resultPing.data.log);
        }
    }, [setPingReceived, setDisablePing, dados, config]);


    useEffect(() => {
        if (!buscouIns) {
            buscarIns();
        } else if (!buscouEquipamento) {
            buscarEquipamento();
        }
        else if (leituraPendente) {
            buscarTelemetria();
        }
    }, [buscouIns, buscouEquipamento, leituraPendente, buscarEquipamento, buscarIns, buscarTelemetria]);

    return (
        <>
            <RidsGlobalStyle></RidsGlobalStyle>
            <icons.SvgSprite></icons.SvgSprite>

            <AlertBox identifier="ALERT_TELEMETRIA" alertPositionType="fixed" alertsPosition="bottom-right" ></AlertBox>

            <GrupoInfo>
                <Cabecalho>
                    <Subtitulo>
                        Informações
                    </Subtitulo>
                    <Botao style={{ marginRight: "24px" }}
                        variant='outlined'
                        title='Atualizar'
                        onClick={() => setLeituraPendente(true)}
                        disabled={disableRefresh}></Botao>
                    <Botao variant='outlined' title='Exportar' icon={<ContainerIcon><icons.Download></icons.Download></ContainerIcon>}
                        onClick={exportPdf}></Botao>
                </Cabecalho>
                <ContainerDispositivo>
                    <Propriedades>
                        <ShowProperty label={"OLT"} value={dados.oltIp}></ShowProperty>
                        <ShowProperty label={"Placa/Porta"} value={[dados.slot, "/", dados.portapon]}></ShowProperty>
                        <ShowProperty label={"ONU"} value={dados.localizacao}></ShowProperty>
                        <ShowProperty label={"SN"} value={dados.serial}></ShowProperty>
                        <ShowProperty label={"Modelo"} value={dados.modelo}></ShowProperty>
                        <ShowProperty label={"Nome"} value={dados.nomeCliente}></ShowProperty>
                    </Propriedades>
                    <Propriedades>
                        <ShowProperty label={"Data Autorização"} value={dados.dataAutorizacao}></ShowProperty>
                        <ShowProperty label={"Tipo ONU"} value={dados.tipoOnu}></ShowProperty>
                        <ShowProperty label={"VLAN"} value={dados.vlandados}></ShowProperty>
                        <ShowProperty label={"Tipo WAN"} value={dados.tipoWan}></ShowProperty>
                        <ShowProperty label={"IP WAN"} value={dados.interfaces ? dados.interfaces[0].currentip : '-'}></ShowProperty>
                        <ShowProperty label={"IP WAN TR-069"} value={dados.interfaces ? dados.interfaces[1].currentip : '-'}></ShowProperty>
                    </Propriedades>
                    <Propriedades>
                        <ShowProperty label={"Usuário"} value={dados.usuarioPppoe}></ShowProperty>
                        <ShowProperty label={"Senha"} value={dados.senhaPppoe}></ShowProperty>
                        <ShowProperty label={"Concentrador"} value={[dados.concentradorNome]}></ShowProperty>
                        <ShowProperty label={"IP Concentrador"} value={[dados.concentradorIp]}></ShowProperty>
                        <ShowProperty label={"IP/VLAN Gerência"} value={dados.ipGerencia}></ShowProperty>
                        <ShowProperty label={"Uptime"} value={dados.uptime}></ShowProperty>
                    </Propriedades>
                </ContainerDispositivo>
            </GrupoInfo>
            <ValoresTelemetria>
                <Cabecalho>
                    <Subtitulo>
                        Análise de valores de telemetria
                    </Subtitulo>
                    <Botao hide={!canReset} style={{ marginRight: "12px" }} variant='outlined' title='Reiniciar'
                        disabled={isResetting}
                        onClick={reiniciar}></Botao>
                    <Botao hide={!canEnableAccess} style={{ marginRight: "12px" }} variant='outlined'
                        disabled={disableEnableAccess}
                        title='Habilitar Acesso Remoto' onClick={() => setLeituraPendente(true)}></Botao>
                    <Botao hide={!canPing}
                        disabled={disablePing}
                        color='secondary' variant='contained' title='PING'
                        onClick={() => executarPing()}></Botao>
                </Cabecalho>
                <ContainerDispositivo>
                    <Propriedades>
                        <ShowProperty label={"Sinal Ópticos RX ONU/OLT"} value={[dados.rxopticallevel, " / ", dados.oltrxopticallevel]}></ShowProperty>
                    </Propriedades>
                    <Propriedades>
                        <ShowProperty label={"Sinal Ópticos TX ONU/OLT"} value={[dados.txopticallevel, " / ", dados.olttxopticallevel]}></ShowProperty>
                    </Propriedades>
                    <Propriedades>
                        <ShowProperty label={"Temperatura"} value={dados.temperatura}></ShowProperty>
                    </Propriedades>
                    <Propriedades>
                        <ShowProperty label={"Voltagem"} value={dados.voltagem}></ShowProperty>
                    </Propriedades>
                </ContainerDispositivo>
            </ValoresTelemetria>
            <Trafego>
                {dados.macsVinculados &&
                    <ContainerEquipamentos>
                        <Subtitulo>
                            Equipamentos Vinculados
                        </Subtitulo>

                        <table style={{ maxWidth: "624px", borderCollapse: "collapse" }}>
                            <thead>
                                <tr>
                                    <ColunaHeader>MAC ADDRESS</ColunaHeader>
                                    <ColunaHeader>VLAN</ColunaHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {(dados.macsVinculados || []).map((f, i) =>
                                (<tr key={f.macaddress + i}>
                                    <ColunaDado><span>{f.macaddress}</span></ColunaDado>
                                    <ColunaDado><span>{f.vlan}</span></ColunaDado>
                                </tr>)
                                )}
                            </tbody>
                        </table>
                    </ContainerEquipamentos>}

                {dados.alarmesEquipamento &&
                    <ContainerAlarmes>
                        <Subtitulo>
                            Lista de Alarmes
                        </Subtitulo>

                        <table style={{ borderCollapse: "collapse" }}>
                            <thead>
                                <tr>
                                    <ColunaHeader>ORDEM</ColunaHeader>
                                    <ColunaHeader>AUTHPASS TIME</ColunaHeader>
                                    <ColunaHeader>OFFLINE TIME</ColunaHeader>
                                    <ColunaHeader>CAUSE</ColunaHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {(dados.alarmesEquipamento || []).map((f, i) =>
                                (<tr key={f.ordem + i}>
                                    <ColunaDado><span>{f.ordem}</span></ColunaDado>
                                    <ColunaDado><span>{f.authpasstime}</span></ColunaDado>
                                    <ColunaDado><span>{f.offlinetime}</span></ColunaDado>
                                    <ColunaDado><span>{f.cause}</span></ColunaDado>
                                </tr>)
                                )}
                            </tbody>
                        </table>
                    </ContainerAlarmes>}
            </Trafego>
            {
                pingReceived ?
                    <WindowWrapper
                        onClose={() => { setPingReceived(""); setDisablePing(false); }}>
                        <Console output={pingReceived}></Console>
                    </WindowWrapper>
                    : <></>
            }
        </>
    );
}