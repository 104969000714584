import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 16px;
  padding: 25px 70px 25px 70px;
  background-color: #F3F3F4;
  @media print {
    row-gap: 16px;
    padding: 30px;
  }
`;

export const Titulo = styled.h2`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700; 
  font-size: 24px;
  color: #29C7CE;
  align-items: flex-start;
  @media print {
    margin: 0;
    &:before{
      content: "RELATÓRIO - ";
    }
    font-weight: 600;
  }
`;

export const Subtitulo = styled.h3`
  font-family: 'Poppins';
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  color: #29C7CE;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  @media print {
    line-height: 24px;
    margin: 0;
    border-bottom: solid 1px;
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 6px;
  }
`;

export const ContainerDispositivo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 18px;
  gap: 20px;
  background-color: white;
  border-radius: 10px;
  @media print {
    padding: 8px;
    gap: 12px;
  }
`;

export const Propriedades = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;

  @media print {
    gap: 4px;
  }
`;

export const Propriedade = styled.h6`
  margin: 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #6179B7;
  @media print {
    font-weight: 600;
  }
`;

export const Texto = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #404040;
`;

export const TextoValor = styled(Texto)`
  margin-bottom: 20px;
  
  @media print {
    margin-bottom: 12px;
  }
`;

export const Botoes = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  column-gap: 24px;
`;


export const ValoresTelemetria = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;


export const Trafego = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;


export const ContainerEquipamentos = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 18px;
  background-color: white;
  border-radius: 10px;
`;


export const ContainerAlarmes = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 18px;
  background-color: white;
  border-radius: 10px;
`;


export const Analise = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  justify-content: space-between;
`;

export const AnalisePing = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const GrupoInfo = styled.div`
`;

export const Cabecalho = styled.div`
   display: flex;
   align-items: center;
   @media print{
    display: block;
   }
`;

export const Dado = styled.h6`
  margin: 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #29C7CE;
`;

export const Valor = styled.span`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #404040;
`;

export const AnaliseConsumo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const Rodape = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #6179B7;
`;