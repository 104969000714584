import styled from "styled-components";

const ColunaHeader = styled.th` 
    padding: 0px 8px;
    height: 40px;
    width: 212px;
    background: #6179B7;

    text-align: right;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14x;
    line-height: 16px;
    color: #FFFFFF;
    @media print {
        border-width: 1px;
        border-style: solid;
        border-color: black;
        color: black;
        margin: 0px;
    }
`;


const ColunaDado = styled.td`
    padding: 0px 12px;
    height: 40px;
    width: 212px;

    text-align: right;

    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

export { ColunaHeader, ColunaDado };



